#edit-company {
  .multiselect-field > div > div:first-child > div:first-child > div {
    border: none !important;
    background-color: transparent !important;
    font-weight: normal !important;
    color: #787878 !important;
    font-size: 16px !important;
    font-family: unset !important;
  }
}
