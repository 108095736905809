.filter {
  height: 36px;
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  color: #a9aaac;
  margin-top: 27px;

  .filter-holder {
    float: left;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 27px;
    padding-right: 0px;
  }
  :first-child {
    padding-left: 0px;
    padding-right: 0px;
  }
  .text-filter {
    input {
      text-indent: 5px;
      width: 383px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px #d7d7d7;
      background-color: #ffffff;
      background-image: url('../../assets/search-icon.png');
      background-repeat: no-repeat;
      background-position: 98% 10px;
    }
    .search-button {
      margin-right: 32px;
      cursor: pointer;
    }
  }

  .multiselect-filter {
    width: 50.5%;
    margin-left: 0px;
    padding-left: 0px;
    text-indent: 5px;
  }

  .rbt-input-hint-container {
    input {
      background-image: url('../../assets/search-icon.png');
      background-repeat: no-repeat;
      background-position: 98% 10px;
    }
  }

  .dropdown-filter {
    select {
      width: 192px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px #d7d7d7;
      background-color: #ffffff;
    }
  }

  .checkbox-filter {
    input {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }
  }

  .date-filter {
    label {
      float: left;
      margin-top: 4px;
      font-weight: bold;
    }
    input {
      width: 128px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px #d7d7d7;
      background-color: #ffffff;
      text-indent: 5px;
    }
  }
  .month-filter {
    label {
      float: left;
      margin-top: 0px;
      font-weight: bold;
    }
    input {
      width: 128px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px #d7d7d7;
      background-color: #ffffff;
      text-indent: 5px;
    }

    #placeHolderfromDate {
      position: relative;
      top: -29px;
      width: 128px;
      height: 36px;
    }
    .react-month-picker .calendar-container {
      width: 210px;
      left: 0;
    }
    .calendar-icon{
      position: relative;
      left: 94px;
      top: -36px;
      border-left: 1px solid #d7d7d7;
      width: 40px;
      padding-left: 8px;
      padding-right: 8px;
      height: 36px;
      margin-bottom: -36px;
      cursor: pointer;

      .calendar{
        position: relative;
        top:6px;
      }
    }
  }

  .search-button {
    width: 70px;
    height: 36px;
    border-radius: 4px;
    box-shadow: 0 3px 0 0 #d0931a;
    background-color: #fcb72d;
    margin-left: 10px;
    cursor: pointer;
    color: white;
    border: none;
  }

  .upload-button {
    width: 152px;
    height: 39px;
    border-radius: 4px;
    border: solid 1px #dcdcdc;
    background-color: #ffffff;
    float: right;
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    color: #2d2926;
    cursor: pointer;
    img {
      padding-right: 10px;
    }
  }
}
