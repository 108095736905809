#new-agreement {
  .multiselect-field > div > div:first-child > div:first-child > div {
    border: none !important;
    background-color: transparent !important;
    font-weight: normal !important;
    color: #787878 !important;
    font-size: 16px !important;
    font-family: unset !important;
  }
  .form-group{
    margin-bottom: 0px;
  }
  #second-row{
    padding-top: 20px;
  }
}
