.paginate-holder {
  width: 100%;
  text-align: right;
  .pagination {
    width: 20%;
    height: 36px;
    border-radius: 4px;
    border: none;
    float: right;
    font-size: 16px;
    font-weight: bold;
    color: #a9aaac;
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #53565a;
    li {
      width: auto;
      margin: auto;
      a {
        cursor: pointer;
        color: #a9aaac;
        line-height: 33px;

        &:hover {
          color: #53565a;
        }
      }
    }
    .selected{
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
      color: #a9aaac;
      a {
        color: #53565a;
      }
    }
    .previous{
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      color: #53565a;
      a {
        color: #53565a;
      }
    }
    .next{
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      left: 0px;
      a {
        color: #53565a;
      }
    }
  }
  .showing-text {
    float: left;
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    color: #4a4a4a;
  }
}
