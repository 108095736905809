.upload-files-modal{
  height: auto;
  width:598px ! important;
  margin: auto;
  vertical-align: middle;
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  text-align: center;
  overflow: hidden;
  .upload-section{
    width:598px;
    height: auto;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    padding-top:45px;
    font-family:'VistaSansOT-Reg';
    .title{
      padding-bottom: 10px;
      font-family:'VistaSansOT';
    }
  }

  .action-buttons{
    margin: auto;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
    .btn-file {
    position: relative;
    overflow: hidden;
    width: 148px;
    height: 36px;
    float: left;
    border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 3px 0 0 #d0931a;
    background-color: #fcb72d;
    font-weight: bold;
    border:none;
    padding: 0;
    .label{
      position: relative;
      top:5px;
    }
}
.btn-file{
  cursor: pointer;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: pointer;
    display: block;
}
#cancel-button{
  cursor: pointer;
}
#done-button{
  cursor: pointer;
}
  }

}

.upload-files-list-modal{
  width:700px ! important;
  margin: auto;
  vertical-align: middle;
  position: relative;
  top: 5vh;
  text-align: center;
  overflow-x: auto;
  max-height: 90vh;
  height: auto;
  .upload-section{
    width:598px;
    height: auto;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    padding-top:45px;
    font-family:'VistaSansOT-Reg';
    .title{
      padding-bottom: 10px;
      font-family:'VistaSansOT';
    }
    .content{
      .uploading{
        color:black;
      }
      .success{
        color:green;
      }
      .failed{
        color:red;
      }
      .headers-section{
        width: 100%;
        div:first-child{
          float: left;
        }
        div:nth-child(2){
          float: right;
          font-family:'VistaSansOT';
        }
      }
      .table-section{
        width: 100%;
        height:auto;
        table{
          width: 100%;
          margin-top:20px;
          border:1px solid #e3e3e3;
          tr{
            td{
              border-bottom:1px solid #e3e3e3;
              padding: 15px;
            }
            td:first-child{
              text-align: left;
              border-right:1px solid #e3e3e3;
            }
          }
        }
      }
    }
  }
  .action-buttons{
    width: 100%;
    margin: 0;
    padding: 20px;
    padding-top: 0px;
    .btn-file {
    position: relative;
    overflow: hidden;
    width: 148px;
    height: 34px;
    float: left;
    border-radius: 4px;
    color: #fcb72d;
    border:1px solid #fcb72d;
    font-weight: bold;
    padding: 0;
    margin-left: 20px;
    .label{
      position: relative;
      top:5px;
    }
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: pointer;
    display: block;
}
#cancel-button, #done-button{
  float: right;
  color:#ffffff;
  height:34px;
}
#cancel-button{
  margin-right: 10px;
  width: 214px;
  box-shadow: 0 3px 0 0 #000000;
  background-color: #4a4a4a;
  cursor: pointer;
}
#done-button{
  width: 115px;
  height:34px;
  cursor: pointer;
}
  }

}
