
.button-field{
  .button-field-child{
      width: 170px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px #649fe4;
      background-color: #ffffff;
      float: left;
      margin-right: 35px;
      span{
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 36px;
        letter-spacing: 0.6px;
        color: #649fe4;
    }
  }
  .additionalHtml{
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 5px;
  }
}

.text-field{
  .units{
  }
}

.multiselect-field{
  button{
  //  all: unset ! important;
  }
}

.react-input-class{
  display: block;
}

.react-input-class-hide{
  display: none;
}
