.option-modal{
  min-height: 200px;
  width: 465px;
  padding: 15px;
  height: 399px;
  z-index:2200;
  text-align: center;
  font-family: 'VistaSansOT-Reg';
  border-radius: 4px;
  border: solid 1px #f5a623;
  background-color: #ffffff;
  vertical-align: middle;
  position: relative;
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  .title{
    font-family: 'VistaSansOT';
    font-size: 18px;
    font-weight: bold;
    color: #53565a;
    padding-left: 23px;
    padding-top: 10px;
    text-align: left;
  }
  .option-container{
    width: 100%;
    text-align: left;
    padding-top:15px;
    padding-left: 30px;
    .option-item{
      display: inline-block;
      padding-right: 20px;
      label{
        margin-left: 10px;
      }
    }
    .option-item:first-child{
      padding-right: 55px;
    }
  }
  .option-detail-container{
    height: 165px;
    padding-top: 20px;
    padding-left: 30px;
    p{
      text-align: left;
      font-family: 'VistaSansOT-Reg';
      font-size: 14px;
      color: #2d2926;
    }
    .email-notification-box{
      width: 376px;
      height: 103px;
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      background-color: #fafafa;
      padding: 15px;
      padding-left: 25px;
      .notification-item{
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
        font-family: 'VistaSansOT-Reg';
         font-size: 14px;
         color: #2d2926;
         label{
           margin-left: 10px;
         }
      }
    }
  }

  .button-container{
    width: auto;
    height: auto;
    margin: auto;
    text-align: center;
  }
  button{
    margin: 10px;
    width: 148px;
    height: 36px;
    position: relative;
    top: 20px;
    border-radius: 4px;
    background-color: #a9aaac;
    color: #ffffff;
    font-weight: bold;
    border:none;
    margin-right: 26px;
    cursor: pointer;
    display: inline-block;
  }

  .yes-btn{
    margin-right: 10px;
    background-color: #fcb72d;
    color: #ffffff;
    cursor: pointer;
  }
}

.button{
  margin: auto;
  width: 148px;
  height: 36px;
  font-weight: bold;
  border:none;
  border-radius: 4px;
  border: solid 1px #f5a623;
  background-color: #ffffff;
  color: #f5a623;

}

.no-btn{
  margin-right: 10px;
  background-color: #e8e8e8;
  color: #4a4a4a;
}

.ReactModal__Overlay--after-open{
  z-index: 2100;
}
