@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700');
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App{
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
}
