.message-modal{
  min-height: 200px;
  width: 400px;
  padding: 15px;
  height: 200px;
  z-index:2200;
  text-align: center;
  font-family: 'VistaSansOT-Reg';
  border-radius: 4px;
  border: solid 1px #f5a623;
  background-color: #ffffff;
  vertical-align: middle;
  position: relative;
  position: relative;
  top: 45%;
  transform: translateY(-50%);

  .message-container{
    text-align: center;
    padding: 20px;
  }
}

.button{
  margin: auto;
  width: 148px;
  height: 36px;
  font-weight: bold;
  border:none;
  border-radius: 4px;
  border: solid 1px #f5a623;
  background-color: #ffffff;
  color: #f5a623;

}

.no-btn{
  margin-right: 10px;
  background-color: #e8e8e8;
  color: #4a4a4a;
}

.ReactModal__Overlay--after-open{
  z-index: 2100;
}
