.customers-container{
  table{
    tr{
      th:first-child{
        width: 120px;
      }
      td:first-child{
        width: 120px;
      }
      th:nth-child(2){
        width: 120px;
      }
      td:nth-child(2){
        width: 160px;
        cursor: pointer;
      }
      td:nth-child(3){
        width: 240px;
      }
      td:nth-child(4){
        width: 140px;
      }
      th:nth-child(5){
        width: 120px;
      }
      td:nth-child(5){
        width: 120px;
      }
      td:last-child{
        width: 185px ! important;
        label:nth-child(2){
          width: 70px;
        }
      }
      th:last-child{
        width: 185px ! important;
        label:nth-child(2){
          width: 70px;
        }
      }
      td{
        word-break: break-word;
      }
    }
  }
}

.staffs-container{
  table{
    tr{
      th:first-child{
        width: 120px;
      }
      td:first-child{
        width: 120px;
      }
      th:nth-child(2){
        width: 200px;
      }
      td:nth-child(2){
        width: 180px;
        cursor: pointer;
      }
      td:nth-child(3){
        width: 240px;
      }
      td:nth-child(4){
        width: 100px;
      }
      th:nth-child(5){
        width: 120px;
      }
      td:nth-child(5){
        width: 120px;
      }
      td:last-child{
        width: 185px ! important;
        label:nth-child(2){
          width: 70px;
        }
      }
      th:last-child{
        width: 185px ! important;
        label:nth-child(2){
          width: 70px;
        }
      }
      td{
        word-break: break-word;
      }
    }
  }
}
