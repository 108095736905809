.confirm-modal{
  min-height: 200px;
  width: 400px;
  padding: 15px;
  height: 200px;
  z-index:2200;
  text-align: center;
  font-family: 'VistaSansOT-Reg';
  border-radius: 4px;
  border: solid 1px #f5a623;
  background-color: #ffffff;
  vertical-align: middle;
  position: relative;
  position: relative;
  top: 45%;
  transform: translateY(-50%);

  .button{
    margin: 10px;
    width: 148px;
    height: 36px;
    float: right;
    position: relative;
    top: 60px;
    border-radius: 4px;
    background-color: #fcb72d;
    color: #ffffff;
    font-weight: bold;
    border:none;
    margin-right: 30px;
    cursor: pointer;
  }

  .no-btn{
    margin-right: 10px;
    background-color: #a9aaac;
    color: #ffffff;
    cursor: pointer;
  }
}



.ReactModal__Overlay--after-open{
  z-index: 2100;
}
