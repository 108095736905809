.download-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 46px;
  border: 1px solid #dcdcdc;
  color: #2d2926;
  font-family: VistaSansOT;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  &:hover {
    transition: 0.3s;
    text-decoration: none;
    transform: scale(1.05);
  }

  .icon {
    margin-right: 10px;
    width: 12px;
    height: 15px;
  }
}
