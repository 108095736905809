#invoice-container{
  table{
    tr{
      td:first-child{
        width: 100px;
      }
      th:first-child{
        width: 100px;
      }
      td:nth-child(2){
        width: 165px;
      }
      td:nth-child(3){
        width: 165px;
      }
      td:nth-child(4){
        width: 145px;
      }
      td:nth-child(5){
        width: 355px;
        cursor: pointer;
      }
      td{
        text-align: left;
      }
      th{
        text-align: center;
      }
      td{
        word-break: break-word;
      }
    }
  }
}

#statements-container{
  table{
    tr{
      td:first-child{
        width: 100px;
      }
      th:first-child{
        width: 100px;
      }
      td:nth-child(2){
        width: 258px;
      }
      td:nth-child(3){
        width: 165px;
        cursor: pointer;
      }
      td:nth-child(4){
        width: 345px;
        text-align: right;
        padding-right: 31px;
      }
      td{
        text-align: left;
      }
      th{
        text-align: center;
      }
      th:nth-child(4){
        div{
          text-align: right;
          float: right;
          padding-right: 0px;
        }
        text-align: right;
        float: none;
      }
    }
  }
}

    .react-month-picker .selected_cell{
      background-color: #fcb72d ! important;
    }
    .react-month-picker .col_mp:hover{
      background-color:#fff3da ! important;
    }
