.loading-div{
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 2200;

  img{
    width: 20%;
    height: 30%;
    position: relative;
    left: 40%;
    top: 35%;
  }

}
.hide-div{
  display: none;
}
