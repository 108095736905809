.header{
  .dropdown{
    cursor: pointer;
    height:55px;
    div:first-child{
      width: 194px;
      padding-left: 0px;
      padding-top: 14px;
      padding-bottom:14px;
    }
    .dropdown-item{
      font-family: 'VistaSansOT';
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #2d2926;
      cursor: pointer;
    }
    .dropdown-item:active{
      background-color: #ffffff;
    }
    .dropdown-menu{
      position: relative;
      margin: 18px 0;
      padding: 18px 20px;
      background-color: #fafafa;
      /* easy rounded corners for modern browsers */
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      .notch{
        position: absolute;
      top: -10px;
      left: 20px;
      margin: 0;
      border-top: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fafafa;
      padding: 0;
      width: 0;
      height: 0;
      /* ie6 height fix */
      font-size: 0;
      line-height: 0;
       /* ie6 transparent fix */
      _border-right-color: pink;
      _border-left-color: pink;
      _filter: chroma(color=pink);
      }
    }
    /* this CS forms the triangles */
.show:after,
.show:before {
    content: '';
    display: block;
    position: absolute;
      left: 10px;
      margin-left: 7px;
      margin-bottom:10px;
      top: -10px;
      width: 0;
      height: 0;
    border-style: solid;
}


/* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
.show:after {
    top: -20px;
    left:13px;
    border-color: transparent transparent #fafafa transparent;
    border-width: 10px;
}

/* this border color controlls the outside, thin border */
.show:before {
    top: -22px;
    left:12px;
    opacity: 0.25;
    border-color: transparent transparent #000000 transparent;
    border-width: 11px;
}
  }
}
