@import '../../base';

.submenu {
  height: 36px;
  width: 100%;
  background-color: #fafafa;
  .submenu-items {
    width: 1023px;
    margin: auto;

    &.auto-width {
      width: auto;
    }
  }
  .submenu-item {
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    color: #fcb72d;
    float: left;
    margin-left: 25px;
    margin-right: 25px;
    color: #9b9b9b;
    cursor: pointer;
    .menu-count {
      border-bottom: none;
      position: relative;
      background-color: #9b9b9b;
      color: white;
      width: 30px;
      border-radius: 21px;
      font-size: 16px;
      top: -23px;
      left: 70px;
    }
  }
  .submenu-item:first-child {
    margin-left: 0;
  }
  .submenu-item-first {
    margin-left: 0;
  }

  .submenu-item-active {
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    color: #fcb72d;
    height: 28px;
    border-bottom: solid 2px #fcb72d;
    float: left;
    .menu-count {
      border-bottom: none;
      position: relative;
      background-color: $primaryColor;
      color: white;
      width: 30px;
      border-radius: 21px;
      font-size: 16px;
      top: -23px;
      left: 70px;
    }
  }

  .submenu-button {
    height: 15px;
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    color: #fcb72d;
    float: right;
    cursor: pointer;
    .dropdown {
      height: 36px;
      font-size: 14px;
      font-weight: bold;
      color: #2d2926;
      .submenu-dropdown-item {
        font-size: 14px;
        padding: 8px;
        padding-left: 14px;
      }
      .dropdown-divider {
        display: none;
      }
    }
    span {
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.6px;
      color: #fcb72d;
      letter-spacing: normal;
    }
  }
}
