.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 100vh;
}

.ReactModal__Content--after-open {
  border-radius: 4px;
  background-color: #ffffff;
  height: 50vh;
}
.common-modal {
  width: 970px;
  min-height: 250px;
  margin: auto;
  margin-top: 50px;
  overflow-y: auto;
  overflow-x: hidden;

  .title-text {
    width: 913px;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: #252525;
    padding-top: 50px;
    padding-left: 30px;
    padding-bottom: 26px;
  }

  .submenu {
    padding-left: 30px;
    width: 913px;
    .text-field {
      width: 354px;
      float: left;
    }
    .dropdown-field {
      width: 265px;
      float: left;
      margin-left: 15px;
    }
    .checkbox-field {
      width: 200px;
      float: right;
      text-align: right;
      margin-bottom: 0;
      margin-top: 22px;
      .form-control {
        width: 20px;
        float: right;
      }
      .form-label {
        float: right;
        margin-right: 15px;
        margin-top: 6px;
        font-weight: bold;
      }
    }
  }

  .pilots-table {
    width: 913px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-height: 50vh;
    overflow-y: auto;
    margin-bottom: 5vh;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    .table-container {
      thead tr:nth-child(1) th {
        background: black;
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }
  }

  .upload-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 10vh;
  }

  .action-buttons {
    position: relative;
    margin-left: 612px;
    margin-right: auto;
    width: 335px;
    color: #ffffff;
    button {
      width: 148px;
      height: 36px;
      float: left;
      border-radius: 4px;
      border-radius: 4px;
      box-shadow: 0 3px 0 0 #d0931a;
      background-color: #fcb72d;
      font-weight: bold;
      border: none;
      padding: 0;
      span {
        line-height: 36px;
      }
    }
    button:first-child {
      margin-right: 25px;
      box-shadow: 0 3px 0 0 #929292;
      background-color: #a9aaac;
    }
  }
}

.change-email-setting-modal {
  width: 465px;
  height: auto;
  min-height: 280px;
  padding: 40px;
  margin: auto;
  margin-top: 179px;
  border-radius: 0;
  overflow-y: auto;
  overflow-x: hidden;
  .email-setting-wrapper {
    .title {
      font-family: VistaSansOT;
      font-size: 18px;
      color: #53565a;
      margin-bottom: 20px;
    }
  }
  .warning {
    margin-top: 22px;
    p {
      font-family: VistaSansOT-Reg;
      font-size: 14px;
      color: #2d2926;
    }
  }

  .radio-wrapper {
    display: flex;
  }
  .round {
    position: relative;

    label {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #dcdcdc;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      left: 0;
      top: 0;
      cursor: pointer;
      &:after {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 50%;
        left: 4px;
        top: 4px;
        background-color: #fcb72d;
        opacity: 0;
        position: absolute;
      }
      + span {
        margin: 0 45px 0 20px;
        font-family: VistaSansOT-Reg;
        font-size: 14px;
        color: #2d2926;
      }
    }

    input[type='radio'] {
      visibility: hidden;
      &:checked + label:after {
        opacity: 1;
      }
      &:checked + label + span {
        font-family: VistaSansOT;
      }
    }
  }

  .action-buttons {
    margin: 79px auto 0 auto;
    width: 335px;
    color: #ffffff;
    button {
      width: 148px;
      height: 36px;
      float: left;
      border-radius: 4px;
      border-radius: 4px;
      box-shadow: 0 3px 0 0 #d0931a;
      background-color: #fcb72d;
      font-weight: bold;
      border: none;
      padding: 0;
      span {
        line-height: 36px;
        color: #ffffff;
        font-family: VistaSansOT;
        font-size: 14px;
      }
    }
    button:first-child {
      margin-right: 25px;
      box-shadow: 0 3px 0 0 #929292;
      background-color: #a9aaac;
    }
  }
}
